<template>
    <div>
        <div class="p-t-10 text-center " v-if="tagList.length>1">
          <div v-for="tag in tagList" :key="tag.key"  class="p-l-20 p-r-20 m-b-2"
            @click="handleChangeTag(tag.key)" :class="currentTag=== tag.key?'workplatform-radio-button-active':'workplatform-radio-button'">
            {{tag.name}}</div>
        </div>

        <Divider dashed><span class="divider-text">{{tagName}}账单</span></Divider>
        <component :is="searchComponent"></component>
    </div>
</template>

<script>
export default {
  components: {
    transactionReconciliationComponent: () => import('../common/transactionReconciliation'),
    maintenanceReconciliationComponent: () => import('../common/maintenanceReconciliation')
  },
  data () {
    return {
      searchComponent: '',
      currentTag: 0,
      tagName: '',
      tagList: []
    }
  },
  created () {
    this.initPage()
  },
  methods: {
    initPage () {
      if (this.isAuth('reconciliation_transaction_view')) {
        this.tagList.push({ key: 1, name: '交易' })
      }
      if (this.isAuth('reconciliation_maintenance_view')) {
        this.tagList.push({ key: 2, name: '运维' })
      }

      if (this.tagList.length > 0) {
        this.handleChangeTag(this.tagList[0].key)
      }
    },
    handleChangeTag (tag) {
      this.currentTag = tag
      this.tagName = this.tagList.find(x => x.key === tag).name
      let moduleType = ''

      switch (tag) {
        case 1:
          moduleType = 'transaction' // 交易
          break
        case 2:
          moduleType = 'maintenance' // 运维
          break
        default:
          break
      }

      this.searchComponent = moduleType + 'ReconciliationComponent'
      this.$store.commit('set_reconciliationDemo_moduleType', moduleType)
    }
  }
}
</script>
